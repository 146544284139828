<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Axios from "../../../config/axios";
import store from "../../../state/store";

export default {
  props: ["app_settings"],
  data() {
    return {
      fields: {
        facebook: "",
        twitter: "",
        whatsapp: "",
        instagram: "",
        phone_number: "",
        email: "",
      },
      value: ["javascript"],
      date: null,
    };
  },
  watch: {
    app_settings(value) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.fields[key] = value[key];
        }
      }
    },
  },
  methods: {
    handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      Axios.post("/admin/app-settings", this.fields)
        .then(() => {
          alert("Modification enregistrée !");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <form action="javascript:void(0);" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-danger shadow">
            <i class="ri-phone-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="phoneNumber"
          placeholder="Telephone"
          v-model="fields.phone_number"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-danger shadow">
            <i class="ri-mail-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="Email"
          placeholder="Email"
          v-model="fields.email"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-secondary shadow">
            <i class="ri-facebook-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="facebookLink"
          placeholder="facebook"
          v-model="fields.facebook"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-warning shadow">
            <i class="ri-whatsapp-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="whatsappNumber"
          placeholder="whatsapp"
          v-model="fields.whatsapp"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-primary shadow">
            <i class="ri-instagram-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="instagramLink"
          placeholder="instagram"
          v-model="fields.instagram"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span class="avatar-title rounded-circle fs-16 bg-secondary shadow">
            <i class="ri-twitter-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="twitterLink"
          placeholder="twitter"
          v-model="fields.twitter"
        />
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" class="btn btn-primary">Modifier</button>
          <button type="button" class="btn btn-soft-success">Annuler</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>
