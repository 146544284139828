<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import { VueEditor } from "vue3-editor";

import Axios from "../../../config/axios";
import store from "../../../state/store";

export default {
  props: ["app_settings"],
  components: {
    VueEditor,
  },
  data() {
    return {
      fields: {
        website_name: "",
        location: "",
        working_hours: "",
        currency: "",
        lang: "",
        about_us: "",
      },
      title: "Configuration Generale",
      date: null,
    };
  },
  watch: {
    app_settings(value) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.fields[key] = value[key];
        }
      }
    },
  },
  mounted() {},
  methods: {
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      Axios.post("/admin/app-settings", this.fields)
        .then(() => {
          alert("Modification enregistrée !");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <form action="javascript:void(0);" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-12 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span
            class="avatar-title rounded-circle fs-16 shadow"
            style="background-color: #fac"
          >
            <i class="ri-code-s-slash-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="WebSite_Name"
          placeholder="WebSite Name"
          v-model="fields.website_name"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span
            class="avatar-title rounded-circle fs-16 shadow"
            style="background-color: #000"
          >
            <i class="ri-map-pin-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="location"
          placeholder="Location"
          v-model="fields.location"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span
            class="avatar-title rounded-circle fs-16 shadow"
            style="background-color: grey"
          >
            <i class="ri-calendar-2-fill"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="Working_Hours"
          placeholder="Working Hours"
          v-model="fields.working_hours"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span
            class="avatar-title rounded-circle fs-16 shadow"
            style="background-color: yellowgreen"
          >
            <i class="ri-money-dollar-circle-line"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="currency"
          placeholder="currency"
          v-model="fields.currency"
        />
      </div>
      <!--end col-->
      <div class="col-6 d-flex align-items-center mb-4">
        <div class="avatar-xs d-block flex-shrink-0 me-3">
          <span
            class="avatar-title rounded-circle fs-16 shadow"
            style="background-color: #000"
          >
            <i class="ri-user-voice-line"></i>
          </span>
        </div>
        <input
          type="text"
          style="height: 45px"
          class="form-control"
          id="language"
          placeholder="Language"
          v-model="fields.lang"
        />
      </div>
      <!--end col-->
      <div class="col-12 d-flex align-items-center mb-4">
        <div>
          <label>A Propos de Nous</label>

          <VueEditor v-model="fields.about_us" />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" class="btn btn-primary">Modifier</button>
          <button type="button" class="btn btn-soft-success">Annuler</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>
