<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Axios from "../../../config/axios";
import store from "../../../state/store";

export default {
  props: ["app_settings"],
  data() {
    return {
      fields: { banner1: "", banner2: "" },
      current_banner: "",
      show: false,
    };
  },

  watch: {
    app_settings(value) {
      this.fields.banner1 = value.banner1;
      this.fields.banner2 = value.banner2;
      console.log(value);
    },
  },
  components: {},

  methods: {
    handleOpenPicker(banner_key) {
      this.current_banner = banner_key;
      this.show = true;
    },
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cropSuccess(imageDataUrl) {
      this.fields[this.current_banner] = imageDataUrl;
    },
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      if (this.app_settings.banner1 != this.fields.banner1) {
        const banner1 = await this.$upload_file(this.fields.banner1, "banner1");
        this.fields.banner1 = banner1;
      }

      if (this.app_settings.banner2 != this.fields.banner2) {
        const banner2 = await this.$upload_file(this.fields.banner2, "banner2");
        this.fields.banner2 = banner2;
      }

      Axios.post("/admin/app-settings", this.fields)
        .then(() => {
          alert("Modification enregistrée !");
        })
        .catch(() => {
          alert("Une erreur est survenur");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
};
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <my-upload
      field="img"
      @crop-success="cropSuccess"
      v-model="show"
      :width="215"
      :height="180"
      img-format="png"
      lang-type="fr"
    ></my-upload>

    <div class="row gap-2">
      <div class="col-4 col-md-4" style="position: relative">
        <img class="w-100" :src="fields.banner1" alt="" />
        <div class="edit-button" @click="handleOpenPicker('banner1')">
          <i class="ri-edit-line"></i>
        </div>
      </div>

      <div class="col-4 col-md-4" style="position: relative">
        <img class="w-100" :src="fields.banner2" alt="" />
        <div class="edit-button" @click="handleOpenPicker('banner2')">
          <i class="ri-edit-line"></i>
        </div>
      </div>
    </div>

    <div class="col-lg-12 mt-4">
      <div class="hstack gap-2 justify-content-end">
        <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </div>
    <!--end col-->
  </form>
</template>

<style scoped>
.edit-button {
  position: absolute;
  right: 5px;
  top: -10px;
  background: gray;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}
</style>
