<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Axios from "@/config/axios";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

// import CKEditor from "@ckeditor/ckeditor5-vue";
import SettingsWebsiteForm from "../../../components/settings/tabs/SettingsWebsiteForm.vue";
import SettingsContactForm from "../../../components/settings/tabs/SettingsContactForm.vue";
import SettingsHomeSliderForm from "../../../components/settings/tabs/SettingsHomeSliderForm.vue";
import SettingsHomeImagesForm from "../../../components/settings/tabs/SettingsHomeImagesForm.vue";
import store from "../../../state/store";

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  page: {
    title: "General Setting",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Configuration Generale",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Configuration Generale",
          active: true,
        },
      ],
      value: ["javascript"],
      date: null,
      app_settings: {},
      fields: { logo: "" },
    };
  },
  watch: {
    app_settings(value) {
      this.fields.logo = value.logo;
    },
  },
  components: {
    Layout,
    // Multiselect,
    // DropZone,
    // ckeditor: CKEditor.component,
    SettingsWebsiteForm,
    SettingsContactForm,
    SettingsHomeSliderForm,
    SettingsHomeImagesForm,
  },
  async beforeRouteEnter() {
    const settings = await Axios.get("/admin/app-settings").then(({ data }) => {
      return data;
    });

    localStorage.setItem("@APP_SETTINGS", JSON.stringify(settings));
  },
  mounted() {
    this.app_settings = JSON.parse(localStorage.getItem("@APP_SETTINGS"));
  },
  methods: {
    async getSettings() {
      const settings = await Axios.get("/admin/app-settings").then(
        ({ data }) => {
          return data;
        }
      );

      this.app_settings = settings;
    },
    async uploadImage(event) {
      const file = event.target.files[0];
      const base64 = await convertBase64(file);
      this.fields.logo = base64;
    },
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);
      const logo_url = await this.$upload_file(this.fields.logo, "logo");
      this.fields.logo = logo_url;

      await Axios.post("/admin/app-settings", this.fields).then(() => {
        alert("Modification enregistrée !");
      });
      store.dispatch("setGlobalLoading", false);
    },
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="@/assets/images/profile-bg.jpg"
          class="profile-wid-img"
          alt=""
        />
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  :src="fields.logo"
                  class="w-100"
                  alt="user-profile-image"
                />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input
                    id="profile-img-file-input"
                    type="file"
                    class="profile-img-file-input"
                    @change="uploadImage"
                  />
                  <label
                    for="profile-img-file-input"
                    class="profile-photo-edit avatar-xs"
                  >
                    <span
                      class="avatar-title rounded-circle bg-light text-body shadow"
                    >
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h4 class="fs-16 mb-1">LOGO DU SITE</h4>
              <!-- <p class="text-muted mb-0">Lead Designer / Developer</p> -->

              <button
                class="btn btn-primary"
                v-if="fields.logo != app_settings.logo"
                @click="handleSubmit"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#websiteDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Configuration générale
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#personalDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Contacts
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#changePassword"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  Images slides
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#experience"
                  role="tab"
                >
                  <i class="far fa-envelope"></i>
                  Images a cote du slide
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="websiteDetails" role="tabpanel">
                <SettingsWebsiteForm :app_settings="app_settings" />
              </div>
              <div class="tab-pane" id="personalDetails" role="tabpanel">
                <SettingsContactForm :app_settings="app_settings" />
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <SettingsHomeSliderForm :app_settings="app_settings" />
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="experience" role="tabpanel">
                <SettingsHomeImagesForm :app_settings="app_settings" />
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
